.centered {
  text-align: center;
}

.table {
  margin-bottom: var(--hugeMargin);
}

.active {
  color: var(--primary1Color);
}

.acknowledgedText {
  color: var(--surface1Color);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unacknowledgedText {
  color: var(--surface3Color);
}

.historyModal {
  height: 682px !important;
  max-width: 700px !important;
}

.historyModalTable {
  margin-bottom: var(--largeMargin);
}

.pagingFooter {
  margin-bottom: var(--largeMargin);
}

.selectedIndex * {
  color: white !important;
}

.severityWrapper {
  width: 60px;
  float: left;
  display: flex;
  justify-content: center;
}

.acknowledgedIcon svg {
  fill: var(--accent1Color);
  color: var(--accent1Color);
}

.filterAsterisk {
  width: 6px;
  margin-left: var(--insetMargin);
  visibility: hidden;
}

.filterAsterisk.visible {
  visibility: visible;
}
