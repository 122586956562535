:root {
  --modelMinInputRowHeight: 30px;
}

.icon {
  margin-right: var(--smallMargin);
}

.hiddenWithLayout {
  visibility: hidden;
}

.hidden {
  display: none;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: var(--modelMinInputRowHeight);
}

.spinner {
  position: relative;
}

.button {
  width: 100%;
}

.labelValue {
  font-size: var(--labelFontSize);
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.errorText {
  margin-bottom: var(--standardMargin);
  animation-name: appear;
  animation-duration: 0.25s;
  font-size: 0.8em;
}

.editorContainer {
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  margin-right: var(--standardMargin);
  margin-bottom: var(--standardMargin);
}

.editorContainer:last-child,
.editorContainer:only-child {
  margin-right: 0;
  margin-bottom: 0;
}
