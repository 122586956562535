.container {
  display: flex;

  align-items: flex-start;
  height: 100%;
  box-sizing: border-box;
}

.canvasContainer {
  flex-grow: 1;
  height: 100%;
  max-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.selected {
  background-color: var(--surface4Color);
}

.libraryHoverPreview {
  background: white;
  padding: var(--standardMargin);
  box-sizing: border-box;
  border-radius: var(--tableBorderRadius);
  box-shadow: var(--selectShadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-all;
  font-size: 0.8em;
}

.libraryHoverPreview > img {
  width: auto;
  height: 70px;
  max-width: 80px;
}

.libraryContent {
  width: auto;
}

.dropdownContent {
  width: min(40vw, 270px);
  max-height: max(70vh, 400px);
  flex-grow: 1;
  overflow-y: auto;
  box-sizing: border-box;
  border-bottom: 1px solid var(--borderColor);
}

.previewSettings > div {
  margin-right: 0;
}

.previewSettings button {
  width: 100%;
  box-sizing: border-box;
}

.buttonContainer {
  padding-right: var(--standardMargin);
}

.previewSettings {
  padding: var(--standardMargin);
  box-sizing: border-box;
}

.previewSettings {
  overflow-y: auto;
}

.svgHeader {
  align-items: center;
}
.svgHeader button {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: var(--largeMargin);
}

.objectEditor {
  height: calc(100%);
  z-index: 2;
  box-sizing: border-box;
}

.objectEditor {
  width: max(400px, 20vw);
  background: white;
  padding: var(--standardMargin);

  box-shadow: var(--navBarShadow);
}

.library {
  background-color: white;
  height: 100%;
  max-height: 70vh;
  overflow-y: auto;
}

.border {
  border: 1px solid var(--borderColor);
}

.libraryMenu {
  padding: var(--smallMargin);
}

.editArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--standardMargin);
}

.imageContainer {
  border: 1px solid var(--borderColor);
  position: relative;
  width: auto;
  display: inline-block;
  flex-grow: 1;
  margin-bottom: var(--standardMargin);
}

.hide {
  display: none;
}

.connection {
  width: 16px;
  height: 16px;
  position: absolute;
  left: calc(100% - 8px);
  top: 10px;
  border-radius: 50%;
  box-sizing: border-box;
}

.libraryList {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.libraryList > div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
}

.libraryList > div:hover {
  background-color: var(--surface5Color);
}

.libraryList > div > img {
  width: 44px;
  height: 44px;
  padding: var(--smallMargin);
  object-fit: scale-down;
  box-sizing: border-box;
}

.editor {
  flex: 1;
  background-color: green;
}

.editContainer {
  display: flex;
  column-gap: 1.5vw;
}

.editArea {
  padding: var(--standardMargin);
  width: 1px;
  flex-grow: 1;
}

.contextMenu {
  position: absolute;
}

.text {
  user-select: none;
}

.svgContainer * {
  vector-effect: non-scaling-stroke;
  shape-rendering: auto;
}

.svgContainer {
  height: 1px;
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  overflow: hidden;
}

.fileInput {
  display: none;
}

.buttonGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--standardMargin);
}

.buttonGrid button,
.buttonGrid label {
  margin: 0 !important;
}
