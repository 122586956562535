.columnLayout {
  display: flex;
  align-items: center;
  width: 100%;
}

.centered {
  text-align: center;
}

.rowTitles {
  flex-grow: 1;
  width: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rowTitle {
}

.rowSubtitle {
  font-size: var(--subtitleFontSize);
  color: var(--surface3Color);

  a {
    color: var(--surface3Color);
  }
}

a.rowSubtitleLink {
  font-size: var(--subtitleFontSize);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--surface3Color);
}
