.button {
  color: var(--surface5Color);
  background: var(--accent1Color);
  display: block;

  &:focus,
  &:hover {
    background: var(--buttonHoverColor);
  }

  ,
  &:active {
    background: var(--buttonActiveColor);
  }

  border: 0px;
  box-sizing: border-box;
  padding: 8px 20px;
  border-radius: 5px;
  text-align: center;

  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  font-family: var(--appFont);
  cursor: pointer;
  transition: background var(--defaultSpeed) ease;
  user-select: none;

  &:active {
    box-shadow:
      inset 0 2px 3px 0 rgba(0, 0, 0, 0.12),
      inset 0 -1px 2px 0 rgba(0, 0, 0, 0.06),
      0 1px 2px 0 rgba(0, 0, 0, 0.11);
  }
}

.button:hover {
  text-decoration: none;
}

.button svg {
  margin-right: var(--iconTextMargin);
  margin-left: -5px;
}

.greyButton {
  color: var(--textColor);
  border: 1px solid;
  border-color: var(--greyButtonBorderColor);
  background: var(--whiteColor);

  padding: 7px 19px;

  &:focus,
  &:hover {
    background: var(--surface5Color);
  }

  ,
  &:active {
    background: var(--surface5Color);
  }
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.compact {
  padding: 6px 16px 6px 16px;
  font-size: 0.9em;
}

.useFullWidth {
  width: 100%;
  flex-grow: 1;
}

.button svg {
  color: var(--surface5Color);
  pointer-events: none;
}

.greyButton svg {
  color: var(--iconColor);
}

.button + .button {
  margin-left: var(--standardMargin);
}

.iconButton + .iconButton {
  margin-left: 0;
}

.iconButton,
.borderlessButton {
  width: var(--iconButtonWidth);

  background: transparent;
  border: 0;

  &:active {
    box-shadow: none;
  }

  &:focus,
  &:hover {
    background: var(--surface5Color);
  }

  ,
  &:active {
    background: var(--surface5Color);
  }
}

.iconButtonBorder {
  border: 1px solid;
  border-color: var(--greyButtonBorderColor);
}

.iconButton {
  padding: 0;
  height: 32px;
}
.iconButton svg {
  margin-right: 0px;
  margin-left: 0px;
}

.iconButton svg,
.borderlessButton svg {
  color: var(--iconColor);
  font-size: var(--baseSvgFontSize);
}

.iconButton:hover,
.borderlessButton:hover {
  color: var(--accent3Color);
  svg {
    color: var(--accent3Color);
  }
}

.borderlessButton {
  color: var(--surface1Color);
}

.iconButton.destructive:hover {
  color: var(--errorColor);
  svg {
    color: var(--errorColor);
  }
}

:root {
  --loadingBarWidth: 20px;
  --loadingBarColor: rgba(255, 255, 255, 0.15);
}

.button.loading {
  background-image: linear-gradient(
    -45deg,
    var(--loadingBarColor) 25%,
    transparent 25%,
    transparent 50%,
    var(--loadingBarColor) 50%,
    var(--loadingBarColor) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: var(--loadingBarWidth) var(--loadingBarWidth);
  -moz-background-size: var(--loadingBarWidth) var(--loadingBarWidth);
  -ms-background-size: var(--loadingBarWidth) var(--loadingBarWidth);
  background-size: var(--loadingBarWidth) var(--loadingBarWidth);
  -webkit-animation: progress 1.5s linear infinite;
  -moz-animation: progress 1.5s linear infinite;
  -ms-animation: progress 1.5s linear infinite;
  animation: progress 1.5s linear infinite;
}

@-webkit-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: calc(var(--loadingBarWidth) * -2) 0px;
  }
}
@-moz-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: calc(var(--loadingBarWidth) * -2) 0px;
  }
}
@-ms-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: calc(var(--loadingBarWidth) * -2) 0px;
  }
}
@keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: calc(var(--loadingBarWidth) * -2) 0px;
  }
}
