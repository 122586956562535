.row {
  border: var(--tableBorderStyle);
  border-bottom: 1px solid transparent;
  display: flex;
  padding: var(--smallMargin);
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;

  &:hover {
    border-color: var(--surface3Color);
  }
}

.row:hover + .row {
  border-top-color: transparent;
}

.row:first-child {
  border-radius: var(--tableBorderRadius) var(--tableBorderRadius) 0px 0px;
}

.row:last-child {
  border-radius: 0px 0px var(--tableBorderRadius) var(--tableBorderRadius);
}

.selectedRow:only-child,
.row:only-child {
  border-radius: var(--tableBorderRadius);
}

.row:nth-child(odd) {
  background: var(--tableOddRowColor);
}

.row:nth-child(even) {
  background: var(--tableEvenRowColor);
}

.row:last-child {
  border-bottom: var(--tableBorderStyle);
}

.row.embedded {
  border-radius: 0;
  border-right: 0;
  border-left: 0;
}

.expansionArea {
  height: var(--dataTableRowHeightStandard);
  margin-top: calc(-1 * var(--smallMargin));
  margin-bottom: calc(-1 * var(--smallMargin));
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.expansionItem {
  transition: transform var(--defaultSpeed);
  margin-right: var(--iconTextMargin);
  min-width: 6px;
}

.expansionItem.isOpen {
  transform: rotateZ(90deg);
}

.rowItem {
  margin-right: var(--iconTextMargin);
  line-height: initial;
}

.rowItem svg {
}

.rowItem svg[data-icon='folder'],
.rowItem svg[data-icon='folder-open'] {
  min-width: 16px;
}

.searchContainer {
  padding-bottom: var(--smallMargin);
  position: sticky;
  top: 0;
  z-index: 2;
}

.searchContainer:before {
  content: '';
  background: var(--surface6Color);
  position: absolute;
  width: 100%;
  height: calc(68px);
  top: -30px;
}

.row.highlighted {
  background: var(--accent1Color);
  color: var(--whiteColor);
  border-color: var(--accent1Color);
}

.row.highlighted + .row {
  border-top-color: transparent;
}

.treeContainer {
  font-size: var(--tableFontSize);
}

.row:last-child:hover {
  border-bottom-color: var(--surface3Color);
}
