.toolbarMenu {
  display: flex;
  border: 1px solid var(--borderColor);
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: var(--tableBorderRadius);
  height: var(--iconButtonHeight);
  box-sizing: border-box;
}

.toolbarMenuItem {
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.withTitle {
  display: flex;
  align-items: center;
}

.withTitle svg {
  margin-right: var(--smallMargin);
}

.toolbarMenuItem:first-child {
  border-radius: var(--tableBorderRadius) 0 0 var(--tableBorderRadius);
  .toolbarMenuButton {
    margin-left: var(--insetMargin);
  }
}

.toolbarMenuItem:last-child {
  border-radius: 0 var(--tableBorderRadius) var(--tableBorderRadius) 0;
  padding-right: var(--insetMargin);
}

.toolbarMenuItem:only-child {
  border-radius: var(--tableBorderRadius);
}

.toolbarMenuItem {
  background: #fff;
  display: flex;
  align-items: center;
  transition: background var(--defaultSpeed) ease;
}

.toolbarMenuButton:hover {
  background: var(--surface5Color) !important;
}

.toolbarMenuButton,
.toolbarMenuItem > button {
  height: calc(var(--iconButtonHeight) - 2px);
  width: var(--iconButtonWidth);
}

.toolbarMenuButton {
  font: inherit;
  border-radius: var(--tableBorderRadius);
  box-sizing: border-box;
  padding: 0;
  text-align: center;
  cursor: pointer;
  user-select: none;
  background: transparent;
  border: 0;

  svg {
    color: var(--toolbarMenuButtonColor);
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.toolbarSeparator {
  margin-top: -1px;
  height: calc(var(--iconButtonHeight) - 2px);
  box-sizing: border-box;
  width: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.toolbarSeparator > div {
  width: 1px;
  height: 100%;
  background: var(--borderColor);
}

.toolbarMenuButton svg[data-icon='plus'] {
  font-size: var(--alternateSvgFontSize);
}

.loading {
  position: absolute;
  width: 40%;
  height: 2px;
  bottom: 2px;
  left: 0;
  right: 0;
  margin: auto;
}

.withTitle {
  width: auto;
  padding-left: var(--smallMargin);
  padding-right: var(--smallMargin);
  font-size: 13px;
}

.toolbarMenuButton.highlighted,
.toolbarMenuButton.highlighted:hover {
  background: var(--accent2Color) !important;
  color: #fafafa;
}

.toolbarMenuButton.highlighted > svg {
  color: var(--whiteColor);
}
